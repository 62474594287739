import { InternalLink } from '@/atoms/InternalLink'
import { LegacyImage as Image } from '@/atoms/LegacyImage'
import { HeadingLG } from '@/atoms/Text'
import { paths } from '@/constants/paths'

interface PressHeaderProps {
  logo: string
}

export const PressHeader: React.FC<PressHeaderProps> = ({ logo }) => {
  return (
    <div className="flex h-80 flex-col items-center justify-center bg-black">
      <InternalLink className="cursor-pointer no-underline" href={paths.press.index}>
        <HeadingLG color="white" weight="bold">
          Press Room
        </HeadingLG>
      </InternalLink>
      <div className="relative mt-8 h-1/3 w-2/3 max-w-[1000px]">
        <Image alt="angel studios logo" className="bg-transparent" layout="fill" objectFit="contain" src={logo} />
      </div>
    </div>
  )
}
