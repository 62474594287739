import React from 'react'
import classNames from 'classnames'
import Markdown from 'marked-react'
import { Button } from '@/atoms/Button'
import { LinkButton } from '@/atoms/Button/LinkButton'
import { AccountCircleIcon } from '@/atoms/Icons/AccountCircleIcon'
import { LegacyImage as Image } from '@/atoms/LegacyImage'
import { CaptionMD, ParagraphLG, ParagraphSM } from '@/atoms/Text'
import { renderer } from '@/services/ContentfulService'
import { TeamMember } from '@/services/PressService'

interface TeamMemberDisplayProps {
  member: TeamMember
  onShowLess: () => void
  onShowMore: () => void
  showMore: boolean
}

export const TeamMemberDisplay: React.FC<TeamMemberDisplayProps> = ({ member, onShowLess, onShowMore, showMore }) => {
  return (
    <div className="flex flex-col gap-6 md:flex-row" key={`${member.fullName}-${member.title}`}>
      <div className="flex flex-none flex-col items-center gap-4 md:w-44 md:items-start">
        <div className="relative h-[170px] w-[170px] overflow-hidden rounded-full shadow-lg shadow-gray-700">
          {member.avatarUrl ? (
            <Image alt={member.fullName || member.title || 'Team Member'} layout="fill" src={member.avatarUrl} />
          ) : (
            <div className="bg-core-gray-400">
              <AccountCircleIcon aria-label="generic-profile-picture" size={170} />
            </div>
          )}
        </div>
        {member.avatarUrl && (
          <LinkButton
            className="rounded-full px-10 py-2"
            external={true}
            href={`https://images.angelstudios.com/image/upload/${member.avatarUrl}`}
            variant="oxide"
          >
            <ParagraphSM className="mx-auto" weight="bold">
              Download
            </ParagraphSM>
          </LinkButton>
        )}
        <div className="text-center md:text-left">
          <ParagraphLG>{member.fullName}</ParagraphLG>
          <CaptionMD>{member.title}</CaptionMD>
        </div>
      </div>
      {member.biography && (
        <div>
          <div className={classNames({ 'max-h-48 line-clamp-6': !showMore })}>
            <Markdown renderer={renderer}>{member.biography}</Markdown>
          </div>
          {showMore ? (
            <Button onClick={onShowLess} variant="plain">
              <ParagraphLG color="copper">See Less</ParagraphLG>
            </Button>
          ) : (
            <Button onClick={onShowMore} variant="plain">
              <ParagraphLG color="copper">See More</ParagraphLG>
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
